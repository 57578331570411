<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
/* eslint-disable no-undef */
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { mapState, mapActions } from "vuex";
import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";
import loadSignalR from "@core/utils/loadSignalR";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  // eslint-disable-next-line implicit-arrow-linebreak
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

let connection;

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapState("accountSettings", ["roles"]),
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    isNotificationsAllowed() {
      const hasAllowedRole = (allowedRoles = [], userRoles = []) => {
        return userRoles.some((role) => allowedRoles.includes(role));
      };
      return hasAllowedRole(
        ["Admin", "Category Manager", "Editor"],
        this.roles
      );
    },
  },

  beforeCreate() {
    if (!localStorage.getItem("lang")) localStorage.setItem("lang", "ka");
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];
    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  async mounted() {
    if (localStorage.getItem("token") !== null) {
      await this.getUserInfo();
    }
    this.setUpSocketConnect();
    window.addEventListener("beforeunload", () => {
      this.stopConnection();
    });
  },
  unmounted() {
    this.stopConnection();
  },
  methods: {
    ...mapActions("notificationModule", ["getNotifications"]),
    ...mapActions("accountSettings", ["getUserInfo"]),

    async stopConnection() {
      if (this.isNotificationsAllowed) return;
      console.log("stoping connection");
      connection.stop();
    },
    async setUpSocketConnect() {
      if (!this.isNotificationsAllowed) return;
      loadSignalR(async () => {
        const token = localStorage.getItem("token");
        connection = new signalR.HubConnectionBuilder()
          .withUrl(process.env.VUE_APP_NOTIFICATIONS_HUB, {
            accessTokenFactory: () => token,
          })
          .configureLogging(signalR.LogLevel.Information)
          .build();
        const start = async () => {
          try {
            await connection.start();
            console.log("SignalR connected!");
          } catch (err) {
            console.log(JSON.stringify(err));
            console.log("SignalR Error:", err);
          }
          connection.on("sendAsync", (x) => {
            console.log("X message", x);
            console.log("Notifications alert received");

            this.getNotifications({ take: 10, skip: 0 });
          });
        };
        start();
      });
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
};
</script>
