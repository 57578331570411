/* eslint-disable */
import { HTTP } from "@/libs/axios";
import { errorsHandler } from "@/helpers/errorsHandler.js";
import QueryBuilder from "@/helpers/QueryBuilder";

const state = () => {
  return {
    promotionsList: [],
    singlePromotion: null,
    singleCode: null,
    isTableBusy: false,
    codesList: [],
  };
};

const getters = {};

const mutations = {
  SET_PROMOTIONS(state, response) {
    state.promotionsList = response.data.data;
  },
  SET_CODES(state, response) {
    state.codesList = response.data.data;
  },
  SET_TABLE(state, response) {
    state.isTableBusy = response;
  },
  SET_PROMOTION(state, response) {
    state.singlePromotion = response.data.data;
  },
  SET_CODE(state, response) {
    state.singleCode = response.data.data;
  },
};

const actions = {
  async getPromotions({ commit }, payload) {
    const params = QueryBuilder(payload);
    commit("SET_TABLE", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/referral/campaigns?` + params)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_PROMOTIONS", response);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
  async getPromotionById({ commit }, payload) {
    commit("SET_TABLE", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/referral/campaigns/${payload.id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_PROMOTION", response);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
  async createPromotion({ commit }, payload) {
    commit("SET_TABLE", true);
    return new Promise((resolve, reject) => {
      HTTP.post(`/referral/campaigns/`, payload)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
  async updatePromotion({ commit }, payload) {
    commit("SET_TABLE", true);
    return new Promise((resolve, reject) => {
      HTTP.put(`/referral/campaigns/${payload.id}`, payload)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
  async deletePromotionById({ commit }, id) {
    commit("SET_TABLE", true);
    return new Promise((resolve, reject) => {
      HTTP.delete(`/referral/campaigns/${id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
  // codes
  async getCodes({ commit }, payload) {
    const params = QueryBuilder(payload);
    commit("SET_TABLE", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/referral/codes?` + params)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_CODES", response);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
  async createOneTimeCode({ commit }, payload) {
    const type = payload.referralType === 1 ? "one-times" : "sales";
    commit("SET_TABLE", true);
    return new Promise((resolve, reject) => {
      HTTP.post(`/referral/codes/${type}`, payload, { responseType: "blob" })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch(async (error) => {
          const responsObj = await error.response.data.text();
          errorsHandler(JSON.parse(responsObj).error);
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
  async getCodeById({ commit }, id) {
    commit("SET_TABLE", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/referral/codes/${id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_CODE", response);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
  async updateCode({ commit }, payload) {
    commit("SET_TABLE", true);
    return new Promise((resolve, reject) => {
      HTTP.put(`/referral/codes/${payload.id}`, payload)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
  async deleteCodeById({ commit }, id) {
    commit("SET_TABLE", true);
    return new Promise((resolve, reject) => {
      HTTP.delete(`/referral/codes/${id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
  async exportCodes({ commit }, payload) {
    commit("SET_TABLE", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/referral/codes/${payload.from}/${payload.to}/export`, {
        responseType: "blob",
      })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
