/* eslint-disable */
import axios from "@axios";
import { HTTP } from "@/libs/axios";
import { errorsHandler, successMessage } from "@/helpers/errorsHandler.js";
import QueryBuilder from "@/helpers/QueryBuilder";

const state = () => {
  return {
    product: [],
    products: [],
    algorithmDescriptors: [],
    isTableBusy: false,
  };
};

const getters = {};

const mutations = {
  SET_PRODUCTS(state, response) {
    state.products = response.data.data;
  },
  SET_TABLE(state, response) {
    state.isTableBusy = response;
  },
  SET_PRODUCT(state, response) {
    state.product = response.data.data.product;
  },
  SET_DESCRIPTORS(state, response) {
    state.algorithmDescriptors = response.data.data.descriptors;
  },
  SET_ISTABLEBUSY(state, val) {
    state.isTableBusy = val;
  },
};

const actions = {
  async getProducts({ commit }, payload) {
    commit("SET_TABLE", true);
    const params = QueryBuilder(payload);
    return new Promise((resolve, reject) => {
      HTTP.get(`/product-management/${payload.productType}?` + params)
        .then((response) => {
          commit("SET_PRODUCTS", response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
  async getProductById({ commit }, payload) {
    commit("SET_TABLE", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/product-management/${payload.productType}/${payload.id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_PRODUCT", response);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
  async deleteProductById({ commit }, id) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/product-management/review-products/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_TABLE", false);
        });
    });
  },
  async getAlgorithmDescriptors({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/parameters/algorithmDescriptors`)
        .then((response) => {
          commit("SET_DESCRIPTORS", response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async setProductStatus(_, payload) {
    return new Promise((resolve, reject) => {
      HTTP.put(`/product-management/review-products/${payload.id}`, payload.data)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            successMessage("Successfully", "Product Status Updated");
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async setApprovedProductStatus({ commit }, payload) {
    commit("SET_ISTABLEBUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.put(`/product-management/products/${payload.id}`, payload.data)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  // async exportProducts({ commit }, payload) {
  //   commit("SET_ISTABLEBUSY", true);
  //   return new Promise((resolve, reject) => {
  //     HTTP.get(`/product-management/products/${payload.from}/${payload.to}/export`, { responseType: "blob" })
  //       .then((response) => {
  //         if (response.data.error) errorsHandler(response.data.error);
  //         else {
  //           resolve(response);
  //         }
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       })
  //       .finally(() => {
  //         commit("SET_ISTABLEBUSY", false);
  //       });
  //   });
  // },
  async exportProducts({ commit }, payload) {
    commit("SET_SHOW_LOADING", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/product-management/products/${payload.from}/${payload.to}/export`, { responseType: "blob" })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_SHOW_LOADING", false);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
