import Vue from "vue";
import Vuex from "vuex";

// Modules
import roleStoreModule from "@/views/role-management/roleStoreModule";
import accountStoreModule from "@/views/account-setting/accountStoreModule";
import categoryStoreModule from "@/views/category-management/categoryStoreModule";
import parametersStoreModule from "@/views/parameters/parametersStoreModule";
import userStoreModule from "@/views/user-management/userStoreModule";
import vendorsStoreModule from "@/views/vendor-management/vendorsStoreModule";
import productsStoreModule from "@/views/products/productsStoreModule";
import promotionStoreModule from "@/views/promotion-management/promotionStoreModule";
import addonsStoreModule from "@/views/addons/addonsStoreModule";
import customersStoreModule from "@/views/customer-management/customersStoreModule";
import ordersStoreModule from "@/views/order-management/ordersStoreModule";
import notificationStoreModule from "@/views/notifications-management/notificationStoreModule";
import authStoreModule from "./auth/index";

import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    products: productsStoreModule,
    accountSettings: accountStoreModule,
    userManagement: userStoreModule,
    roleManagement: roleStoreModule,
    categoryManagers: categoryStoreModule,
    vendorManagement: vendorsStoreModule,
    parametersModule: parametersStoreModule,
    addonsModule: addonsStoreModule,
    customersModule: customersStoreModule,
    ordersModule: ordersStoreModule,
    promotionModule: promotionStoreModule,
    notificationModule: notificationStoreModule,
    auth: authStoreModule,
  },
  strict: process.env.DEV,
});
