/* eslint-disable */
import { HTTP } from "@/libs/axios";
import { errorsHandler } from "@/helpers/errorsHandler";
import QueryBuilder from "@/helpers/QueryBuilder";

const state = () => {
  return {
    orders: [],
    isTableBusy: true,
    order: null,
  };
};

const getters = {};

const mutations = {
  SET_ORDERS(state, response) {
    state.orders = response.data.data;
  },
  SET_ISTABLEBUSY(state, val) {
    state.isTableBusy = val;
  },
  SET_ORDER(state, val) {
    state.order = val;
  },
};

const actions = {
  async getOrders({ commit }, payload) {
    const query = QueryBuilder(payload);
    return new Promise((resolve, reject) => {
      commit("SET_ISTABLEBUSY", true);
      HTTP.get(`/order-management/orders?${query}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_ORDERS", response);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async getOrderById({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("SET_ISTABLEBUSY", true);
      HTTP.get(`/order-management/orders/${id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_ORDER", response.data.data.order);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async refundOrderById({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_ISTABLEBUSY", true);
      HTTP.post(`/order-management/orders/${payload.orderId}/items/${payload.orderItemId}/refund`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async updateOrderStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_ISTABLEBUSY", true);
      HTTP.put(`/order-management/orders/${payload.orderId}/items/${payload.orderItemId}/status`, payload.statusObject)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async exportOrders({ commit }, payload) {
    const query = QueryBuilder(payload);
    return new Promise((resolve, reject) => {
        // /admin/order-management/orders/export
      HTTP.get(`/order-management/orders/export?${query}`, { responseType: "blob" })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async refundMoneyByOrderId({commit}, payload) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/order-management/orders/${payload.id}/refund`, { amount: payload.amount })
          .then((response) => {
            if (response.data.error) errorsHandler(response.data.error);
            else {
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {});
    });
  },
  async getOrderSplits({commit}, {id}) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/order-management/orders/${id}/splits`)
          .then((response) => {
            if (response.data.error) errorsHandler(response.data.error);
            else {
              resolve(response.data.data);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {});
    });
  },
  async manualTransferPerSplit({commit}, payload) {
    return new Promise((resolve, reject) => {
      HTTP.patch(`/order-management/orders/${payload.orderId}/splits/${payload.splitId}`, payload)
          .then((response) => {
            if (response.data.error) errorsHandler(response.data.error);
            else {
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {});
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
