import { HTTP } from "@/libs/axios";
import { errorsHandler, successMessage } from "@/helpers/errorsHandler";
import QueryBuilder from "@/helpers/QueryBuilder";

const state = () => {
  return {
    vendors: [],
    applications: null,
    redirectUrl: null,
    contractData: null,
    isTableBusy: false,
  };
};

const getters = {};

const mutations = {
  SET_VENDORS(state, response) {
    state.vendors = response.data;
  },
  SET_APPLICATIONS(state, response) {
    state.applications = response.data;
  },
  SET_REDIRECTURL(state, response) {
    console.log(response);
    state.redirectUrl = response.data.data.url;
  },
  SET_CONTRACT(state, response) {
    state.contractData = response.data.data;
  },
  SET_IS_TABLE_BUSY(state, payload) {
    state.isTableBusy = payload;
  },
};
const actions = {
  async getVendors({ commit }, payload) {
    commit("SET_IS_TABLE_BUSY", true);
    const params = QueryBuilder(payload);
    return new Promise((resolve, reject) => {
      HTTP.get(`/vendor-management/vendors?${params}`)
        .then((response) => {
          commit("SET_VENDORS", response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_IS_TABLE_BUSY", false);
        });
    });
  },
  async getImpersonateUrl({ commit }, email) {
    commit("SET_IS_TABLE_BUSY", true);
    const payload = { email };
    return new Promise((resolve, reject) => {
      HTTP.post(`/vendor-management/vendor-cms-admin-session`, payload)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_REDIRECTURL", response);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_IS_TABLE_BUSY", false);
        });
    });
  },
  async getApplications({ commit }, payload) {
    commit("SET_IS_TABLE_BUSY", true);
    const params = QueryBuilder(payload);
    return new Promise((resolve, reject) => {
      HTTP.get(`/vendor-management/applications?${params}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_APPLICATIONS", response);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_IS_TABLE_BUSY", false);
        });
    });
  },
  async deleteVendor({ commit }, payload) {
    commit("SET_IS_TABLE_BUSY", true);

    return new Promise((resolve, reject) => {
      HTTP.delete(`/vendor-management/vendors/${payload.id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_IS_TABLE_BUSY", false);
        });
    });
  },
  async resetVendorPassword({ commit }, payload) {
    commit("SET_IS_TABLE_BUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.put(`/vendor-management/vendors/${payload.id}/password`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_IS_TABLE_BUSY", false);
        });
    });
  },
  async setVendorApplicationStatus({ commit }, payload) {
    commit("SET_IS_TABLE_BUSY", true);

    return new Promise((resolve, reject) => {
      HTTP.put(`/vendor-management/applications/${payload.id}/status`, {
        status: payload.status,
      })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            successMessage("Successfully", "User Deleted");
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_IS_TABLE_BUSY", false);
        });
    });
  },
  async setVendorAttachement({ commit }, payload) {
    commit("SET_IS_TABLE_BUSY", true);

    return new Promise((resolve, reject) => {
      HTTP.post(`/vendor-management/vendors/${payload.id}/contractAttachment`, payload.formData)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_IS_TABLE_BUSY", false);
        });
    });
  },
  async getVendorAttachement({ commit }, payload) {
    commit("SET_IS_TABLE_BUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/vendor-management/vendors/${payload}/contractAttachment`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_IS_TABLE_BUSY", false);
        });
    });
  },
  async exportVendors({ commit }, payload) {
    commit("SET_IS_TABLE_BUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/vendor-management/vendors/${payload.from}/${payload.to}/export`, { responseType: "blob" })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_IS_TABLE_BUSY", false);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
