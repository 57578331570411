/* eslint-disable */
import axios from "@axios";
import { HTTP } from "@/libs/axios";
import { errorsHandler, successMessage } from "@/helpers/errorsHandler.js";
import QueryBuilder from "@/helpers/QueryBuilder";

const state = () => {
  return {
    regions: null,
    region: null,

    categories: null,
    subCategories: null,
    category: null,

    productAttributes: null,
    allAttributes: null,
    //
    cityDistances: null,
    districtDistances: null,
  };
};

const getters = {};

const mutations = {
  SET_REGIONS(state, response) {
    state.regions = response;
  },
  SET_REGION(state, response) {
    state.region = response.data.region;
  },
  SET_CATEGORIES(state, response) {
    state.categories = response;
  },
  SET_SUB_CATEGORIES(state, response) {
    state.subCategories = response;
  },
  SET_CATEGORY(state, response) {
    state.category = response.data.productCategory;
  },
  CLEAR_CATEGORY(state) {
    state.category = null;
  },
  SET_PRODUCT_ATTRIBUTES(state, response) {
    state.productAttributes = response;
  },
  PUSH_REGION(state, response) {
    state.regions.data.regions.push(response);
  },
  SET_ATTRIBUTES(state, response) {
    state.allAttributes = response.data;
  },
  SET_DISTRICT_DISTANCES(state, response) {
    state.districtDistances = response.data.distances;
  },
  SET_CITY_DISTANCES(state, response) {
    state.cityDistances = response.data.distances;
  },
};

const actions = {
  async getRegions({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/parameters/regions`)
        .then((response) => {
          commit("SET_REGIONS", response.data);
          if (response.data.error) errorsHandler(response.data.error);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async deleteRegionParameters({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/parameters/${payload.type}/${payload.id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getRegion({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/parameters/regions/${payload.id}`)
        .then((response) => {
          commit("SET_REGION", response.data);
          if (response.data.error) errorsHandler(response.data.error);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async updateRegionParameters({ commit }, payload) {
    console.log(payload);
    return new Promise((resolve, reject) => {
      HTTP.put(`/parameters/${payload.type}/${payload.id}`, payload)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async addDistrictToCity(_, data) {
    console.log(data);
    const payload = {
      districts: [
        {
          districtNameGeo: data.districtNameGeo,
          districtNameEng: data.districtNameEng,
          coordinates: "44.523234, 45.23423",
        },
      ],
    };
    return new Promise((resolve, reject) => {
      HTTP.post(
        `/parameters/regions/${data.regionId}/cities/${data.cityId}/districts`,
        payload
      )
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async createRegion(_, payload) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/parameters/regions`, {
        region: payload,
      })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getCategories({ commit }, payload) {
    const params = QueryBuilder(payload);
    return new Promise((resolve, reject) => {
      HTTP.get(`/parameters/productCategories?${params}`)
        .then((response) => {
          commit("SET_CATEGORIES", response.data);
          if (response.data.error) errorsHandler(response.data.error);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async updateAnyCategory({ commit }, payload) {
    const { type, id } = payload;
    delete payload.id;
    delete payload.type;
    return new Promise((resolve, reject) => {
      HTTP.put(`/parameters/${type}/${id}`, payload)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getCategory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/parameters/productCategories/${payload.id}`)
        .then((response) => {
          commit("SET_CATEGORY", response.data);
          if (response.data.error) errorsHandler(response.data.error);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getSubCategories({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/parameters/productSubCategories`)
        .then((response) => {
          commit("SET_SUB_CATEGORIES", response.data);
          if (response.data.error) errorsHandler(response.data.error);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async createCategory(_, payload) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/parameters/productCategories`, {
        productCategory: payload,
      })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async addSubCategoriesToCategory(_, payload) {
    return new Promise((resolve, reject) => {
      HTTP.post(
        `/parameters/productCategories/${payload.categoryId}/subCategories`,
        payload
      )
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async addGenericCategoryToSubCategory(_, payload) {
    return new Promise((resolve, reject) => {
      HTTP.post(
        `/parameters/productCategories/${payload.categoryId}/subCategories/${payload.subCategoryId}/genericCategories`,
        { genericCategory: payload.data }
      )
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getProductAttributes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.get(
        `/parameters/productSubCategories/${payload.id}/productAttributes`
      )
        .then((response) => {
          commit("SET_PRODUCT_ATTRIBUTES", response.data);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async createProductAttributes(_, payload) {
    console.log(payload);
    return new Promise((resolve, reject) => {
      HTTP.post(
        `/parameters/productSubCategories/${payload.id}/productAttributes`,
        {
          attributes: payload.productAttributes,
        }
      )
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            successMessage("Successfully", "Attribute Created");
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async updateProductAttributes(_, payload) {
    console.log(payload);
    return new Promise((resolve, reject) => {
      HTTP.put(`/parameters/productAttributes/${payload.id}`, payload)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            successMessage("Successfully", "Attribute Created");
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async deleteAttribute(_, id) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`/parameters/productAttributes/${id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getAllAttributes({ commit }) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/parameters/productAttributes`)
        .then((response) => {
          commit("SET_ATTRIBUTES", response.data);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async deleteAnyCategory({ commit }, payload) {
    const { id, type } = payload;
    const typesList = {
      category: "productCategories",
      subCategory: "productSubCategories",
      genericCategory: "productGenericCategories",
    };
    return new Promise((resolve, reject) => {
      HTTP.delete(`/parameters/${typesList[type]}/${id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getCityDistances({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/parameters/cities/distances`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_CITY_DISTANCES", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getDistrictDistances({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/parameters/districts/distances`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_DISTRICT_DISTANCES", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getCityDistances({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/parameters/cities/distances`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_CITY_DISTANCES", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async getDistrictDistances({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/parameters/districts/distances`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_DISTRICT_DISTANCES", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
