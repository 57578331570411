/* eslint-disable */
import { HTTP } from "@/libs/axios";
import { errorsHandler } from "@/helpers/errorsHandler.js";
import queryBuilder from "@/helpers/QueryBuilder";

const state = () => {
  return {
    notificationsOptionsList: [],
    isBusy: false,
    notificationsList: {},
  };
};

const getters = {};

const mutations = {
  SET_NOTIFICATIONS_OPTIONS(state, response) {
    state.notificationsOptionsList = response.data.notificationOptions;
  },
  SET_NOTIFICATIONS_LIST(state, response) {
    state.notificationsList = response.data;
  },
  SET_BUSY(state, value) {
    state.isBusy = value;
  },
};
const actions = {
  async getNotificationOptions({ commit }, payload) {
    commit("SET_BUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/notifications/user-notification-options`)
        .then((response) => {
          if (response.data.error) {
            errorsHandler(response.data.error);
          } else {
            commit("SET_NOTIFICATIONS_OPTIONS", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_BUSY", false);
        });
    });
  },
  async updateNotificationOption({ commit }, payload) {
    commit("SET_BUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.put(`/notifications/user-notification-options/${payload.id}`, { option: payload.totalValues })
        .then((response) => {
          if (response.data.error) {
            errorsHandler(response.data.error);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_BUSY", false);
        });
    });
  },
  async getNotifications({ commit }, payload) {
    console.log("getting nofitications");
    const params = queryBuilder(payload);
    commit("SET_BUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/notifications/user-notifications?${params}`)
        .then((response) => {
          if (response.data.error) {
            errorsHandler(response.data.error);
          } else {
            commit("SET_NOTIFICATIONS_LIST", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_BUSY", false);
        });
    });
  },
  async readAllNotifications({ commit }) {
    commit("SET_BUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.put(`/notifications/user-notifications`)
        .then((response) => {
          if (response.data.error) {
            errorsHandler(response.data.error);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_BUSY", false);
        });
    });
  },
  async markNotificationAsRead({ commit }, id) {
    commit("SET_BUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.put(`/notifications/user-notifications/${id}/read-status`)
        .then((response) => {
          if (response.data.error) {
            errorsHandler(response.data.error);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_BUSY", false);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
