/* eslint-disable */
import { HTTP } from "@/libs/axios";
import { errorsHandler } from "@/helpers/errorsHandler.js";
//
//  ['superAdmin', 'editor', 'categoryManager', 'support', 'accounttant', 'analytics']
//

// superadmin yvelaferze aqkvs cvdoma
// editori ver amatebs editors danarcheni giviea rac super admin;

//  categoryManager eმატება იუზერად, ენიჭება კატეგორიის მენეჯერის როლი, და შემდეგ ენიჭება ვენდორები.
//  parametrebshi unda iyos mxolod kategoriebi
//  შეუძლია მხოლოდ ჯენერიქ კატეგორიის ედიტი ... <check with gio>.
//  ვერ ცვლის პროდუქტის დახასიათებას, ცვლის საკომისიოს. // erjerjobit Sheedzlos
//  არ შეუძლია დამატება და როლის მინიჭება ახალ იუზერს.
// produqtebidan amovigot addonebi
// vendor managementshi mxolod vendorebi uchandes da ara aplikaciebi
// promotion ar unda iyos da naxe rato ar modis

//

// support ყელა კატეგორიაში არსებული ყევლა ვენდორი
// საპორტი ხედავს პროდუქტებს მაგრამ არა აქვს მათი რედაქტირების უფლება
// არც დამატების უფლება არც წაშლის უფლება მხოლოდ ნახვა შეუძლია.
// ხედავს ყველა ვენდორს, პროდუქტს შეკვეთას იუზერს. არ აქვს არაფრის ედიტის უფლება.
// statusi sshesccla da refund

// ექაუნთენთი
// ვენდორების, შეკვეთების და პროდუქტების ვიუ და ექსპორტი.

// ანალიტიკოსს აქვს ყველაფრის ვიუ და ფილტრი ექსპორტი მეტი ექშენი არაფერი არა აქვს.

// ვენდორის მხარე
// ['superAdmin', 'contentManager', 'operationalmanager', 'accountant', 'serviceprovider'];
// ხედავს პროფილის ინფოს და ვერ აედიტებს;
// შეუძლია ვენდორის იუზერის დამატების უფლება ადმინის და კონტენტ მენეჯერის გარდა;
// დამატება პროდუქტის
// იუზერ მენეჯმენტი აქვს.
// მომხმარებლებს ხედავს მსზე მიმაგრებულ ფილიალზე;

// operational manager
// ფილიალაებზე აქვს წვდომა, დაამატოს მხოლოდ სერვის პროვაიდერ.
// ხედავს შეკვეთებს და შეუძლია შეკვეთის სტატუსის ცვლიელბა

const state = () => {
  return {
    userRoles: null,
    admins: null,
    isBusy: false,
  };
};

const getters = {};

const mutations = {
  SET_USER_ROLES(state, response) {
    let data;
    if (response.data) {
      data = response.data.roles;
      state.userRoles = data.map((obj) => ({
        roleId: obj.id,
        text: obj.name,
        value: obj.name,
        isEnabled: obj.isEnabled,
      }));
    } else {
      state.userRoles = null;
    }
  },
  SET_ADMINS(state, response) {
    state.admins = response.data.admins.map((obj) => ({
      id: obj.id,
      firstName: obj.firstName,
      displayId: obj.displayId,
      lastName: obj.lastName,
      email: obj.email,
      mobile: obj.mobile,
    }));
  },
  CLEAR_USER_ROLES(state) {
    state.userRoles = null;
  },
  SET_BUSY(state, val) {
    state.isBusy = val;
  },
};
const actions = {
  async getUserRole({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/role-management/admins/${payload.id}/roles`, {
        email: payload.email,
      })
        .then((response) => {
          if (response.data.error) {
            errorsHandler(response.data.error);
          } else {
            commit("SET_USER_ROLES", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  async setUserRole({ commit }, payload) {
    commit("SET_BUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.post(`/role-management/admins/${payload.id}/roles`, {
        roles: payload.roles,
      })
        .then((response) => {
          if (response.data.error) {
            errorsHandler(response.data.error);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_BUSY", false);
        });
    });
  },
  async getAdmins({ commit }, _) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/role-management/admins`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_ADMINS", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
