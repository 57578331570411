/* eslint-disable */
import axios from "@axios";
import { HTTP } from "@/libs/axios";
import { errorsHandler, successMessage } from "@/helpers/errorsHandler.js";

const state = () => {
  return {
    categoryManagers: null,
    categoryManagersWithVendors: null,
    isTableBusy: false,
  };
};

const getters = {};

const mutations = {
  SET_CATEGORY_MANAGERS(state, response) {
    state.categoryManagers = response;
  },
  SET_CATEGORY_MANAGER_WITH_VENDORS(state, response) {
    state.categoryManagersWithVendors = response;
  },
  SET_IS_TABLE_BUSY(state, response) {
    state.isTableBusy = response;
  },
};
const actions = {
  async setCategoryRoles({ commit }, payload) {
    commit("SET_IS_TABLE_BUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.post(
        `/category-manager-roles/category-managers/${payload.id}/vendors`,
        {
          vendors: payload.vendors,
        }
      )
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            successMessage("Successfully", "Vendors Saved");
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_IS_TABLE_BUSY", false);
        });
    });
  },
  async getCategoryManagers({ commit }, _) {
    commit("SET_IS_TABLE_BUSY", true);

    return new Promise((resolve, reject) => {
      HTTP.get(`/category-manager-roles/category-managers`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_CATEGORY_MANAGERS", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_IS_TABLE_BUSY", false);
        });
    });
  },
  async getCategoryManagerWithVendors({ commit }, payload) {
    commit("SET_IS_TABLE_BUSY", true);

    return new Promise((resolve, reject) => {
      HTTP.get(
        `/category-manager-roles/category-managers/${payload.id}/vendors`
      )
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_CATEGORY_MANAGER_WITH_VENDORS", response.data);
            resolve(response);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
        .finally(() => {
          commit("SET_IS_TABLE_BUSY", false);
        });
    });
  },
  async exportCategories({ commit }) {
    commit("SET_IS_TABLE_BUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/parameters/productCategories/export`, {
        responseType: "blob",
      })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_IS_TABLE_BUSY", false);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
