/* eslint-disable import/no-unresolved */
export default [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      pageTitle: "Home",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/account-setting",
    name: "account-setting",
    component: () => import("@/views/account-setting/AccountSetting.vue"),
    meta: {
      pageTitle: "Account Settings",
      breadcrumb: [
        {
          text: "Account Settings",
          active: true,
        },
      ],
    },
  },
  {
    path: "/admin-management",
    name: "admin-management",
    component: () => import("@/views/user-management/UserManagement.vue"),
  },
  {
    path: "/role-management",
    name: "role-management",
    component: () => import("@/views/role-management/RoleManagement.vue"),
  },
  {
    path: "/category-management",
    name: "category-management",
    component: () =>
      import("@/views/category-management/CategoryManagement.vue"),
  },
  {
    path: "/verification/email/fail",
    name: "failure",
    component: () => import("@/views/status-pages/failurePage.vue"),
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/views/products/ApprovedProducts.vue"),
  },
  {
    path: "/pending-products",
    name: "pending-products",
    component: () => import("@/views/products/Products.vue"),
  },

  {
    path: "/verification/email/success",
    name: "success",
    component: () => import("@/views/status-pages/successPage.vue"),
  },
  {
    path: "/password/set",
    name: "passwordset",
    component: () => import("@/views/password-set/PasswordSet.vue"),
    meta: {
      layout: "full",
      isStatusPage: true,
    },
  },
  {
    path: "/regions",
    name: "regions",
    component: () => import("@/views/parameters/Regions.vue"),
  },
  {
    path: "/categories",
    name: "categories",
    component: () => import("@/views/parameters/Categories.vue"),
  },
  {
    path: "/add-category",
    name: "add-category",
    component: () => import("@/views/parameters/AddCategory.vue"),
  },
  {
    path: "/attributes",
    name: "attributes",
    component: () => import("@/views/parameters/Attributes.vue"),
  },
  {
    path: "/distances",
    name: "distances",
    component: () => import("@/views/parameters/Distances.vue"),
  },
  {
    path: "/addons",
    name: "addons",
    component: () =>
      // eslint-disable-next-line implicit-arrow-linebreak
      import("@/views/addons/Addons.vue"),
  },
  {
    path: "/vendors",
    name: "vendors",
    component: () => import("@/views/vendor-management/Vendors.vue"),
  },
  {
    path: "/applications",
    name: "applications",
    component: () => import("@/views/vendor-management/Applications.vue"),
  },
  {
    path: "/customers",
    name: "customers",
    component: () => import("@/views/customer-management/Customers.vue"),
  },
  {
    path: "/customers/:id",
    name: "singleCustomer",
    component: () => import("@/views/customer-management/SingleCustomer.vue"),
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("@/views/order-management/Orders.vue"),
  },
  {
    path: "/orders/:id",
    name: "singleOrders",
    component: () => import("@/views/order-management/SingleOrder.vue"),
  },
  {
    path: "/promotions/campaigns",
    name: "campaigns",
    component: () => import("@/views/promotion-management/Campaigns.vue"),
  },
  {
    path: "/promotions/codes",
    name: "codes",
    component: () => import("@/views/promotion-management/Codes.vue"),
  },
  {
    path: "/notifications/",
    name: "notifications",
    component: () =>
      import("@/views/notifications-management/Notifications.vue"),
  },
  {
    path: "/verification/redirect",
    name: "Redirect",
    // eslint-disable-next-line import/no-extraneous-dependencies
    component: () => import("@/views/status-pages/Redirect.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];
