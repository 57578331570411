import { HTTP } from "@/libs/axios";
import { errorsHandler } from "@/helpers/errorsHandler";
import queryBuilder from "@/helpers/QueryBuilder";

const state = () => {
  return {
    customers: [],
    customer: null,
    customerFavorites: null,
    customerContacts: null,
    customerDescriptors: null,
    isTableBusy: true,
  };
};

const getters = {};

const mutations = {
  SET_CUSTOMERS(state, response) {
    state.customers = response.data.data;
  },
  SET_ISTABLEBUSY(state, val) {
    state.isTableBusy = val;
  },
  SET_CUSTOMER(state, response) {
    state.customer = response.data.data.customer;
  },
  SET_CUSTOMER_FAVORITES(state, response) {
    console.log(response.data.data.favouriteProducts);
    state.customerFavorites = response.data.data.favouriteProducts;
  },
  SET_CUSTOMER_CONTACTS(state, response) {
    state.customerContacts = response?.data.data.contacts;
  },
  SET_CUSTOMER_DESCRIPTORS(state, response) {
    state.customerDescriptors = response?.data.data;
  },
};

const actions = {
  async getCustomers({ commit }, payload) {
    commit("SET_ISTABLEBUSY", true);
    const params = queryBuilder(payload);
    return new Promise((resolve, reject) => {
      HTTP.get(`/customer-management/customers?${params}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_CUSTOMERS", response);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async getCustomerById({ commit }, id) {
    commit("SET_ISTABLEBUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/customer-management/customers/${id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_CUSTOMER", response);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async getCustomerContacts({ commit }, id) {
    commit("SET_ISTABLEBUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/customer-management/customers/${id}/contacts`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_CUSTOMER_CONTACTS", response);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async getCustomerFavorites({ commit }, id) {
    commit("SET_ISTABLEBUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/customer-management/customers/${id}/favourite-products`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_CUSTOMER_FAVORITES", response);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async getCustomerDescriptors({ commit }, id) {
    commit("SET_ISTABLEBUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.get(`/customer-management/customers/${id}/descriptors`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            commit("SET_CUSTOMER_DESCRIPTORS", response);
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async deleteCustomer({ commit }, id) {
    commit("SET_ISTABLEBUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.delete(`/customer-management/customers/${id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async blockCustomer({ commit }, id) {
    commit("SET_ISTABLEBUSY", true);
    return new Promise((resolve, reject) => {
      HTTP.patch(`/customer-management/customers/${id}`)
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
  async exportCustomers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/customer-management/customers/${payload.from}/${payload.to}/export`, { responseType: "blob" })
        .then((response) => {
          if (response.data.error) errorsHandler(response.data.error);
          else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_ISTABLEBUSY", false);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
